import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import SurveyForm from '../components/survey/SurveyForm';

import axios from 'axios';
import useScrollToTop from '../hooks/hook';
import { API_BASE_URL } from '../config';

interface SurveyQuestion {
  id: number;
  questionText: string;
  options: Option[];
}

interface Option {
  id: number;
  optionText: string;
}

const HomePage: React.FC = () => {
  const [questions, setQuestions] = useState<SurveyQuestion[]>([]);
  const [answers, setAnswers] = useState<{ [key: number]: number }>({});
  const [userId] = useState<string>('user_' + Math.random().toString(36).substr(2, 9));
  const [selectedOptions, setSelectedOptions] = useState<{ [key: number]: number }>({});

  const navigate = useNavigate();

  useScrollToTop();

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/survey/questions`);
        const mappedQuestions = response.data.map((q: any) => ({
          id: q.id,
          questionText: q.question_text,
          options: q.options.map((o: any) => ({
            id: o.id,
            optionText: o.option_text,
          }))
        }));
        setQuestions(mappedQuestions);
      } catch (error) {
        console.error('Error fetching questions:', error);
      }
    };
  
    fetchQuestions();
  }, []);  

  const handleOptionChange = (questionId: number, optionId: number) => {
    setSelectedOptions(prev => ({ ...prev, [questionId]: optionId }));
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [questionId]: optionId,
    }));
  };

  const handleSubmit = async () => {
    const formattedAnswers = Object.entries(answers).map(([questionId, selectedOptionId]) => ({
      questionId: Number(questionId),
      selectedOptionId,
      userId,
    }));

    try {
      await axios.post(`${API_BASE_URL}/api/survey/responses`, formattedAnswers);
      navigate('/results', { state: { userId } });
    } catch (error) {
      console.error('Error submitting answers:', error);
    }
  };

  return (
    <div className="l_container">
      <Header />
      <p className="survey-form-dsc">
        挑戦力診断「WATARE」のエッセンシャル版をお試しいただけます。以下の質問に直感的に、自分の心に素直に回答すると、より正確な分析結果が表示されます。そのほか説明する必要がある事柄などがあればここに記載する。
      </p>
      <SurveyForm
        questions={questions}
        onOptionChange={handleOptionChange}
        onSubmit={handleSubmit}
      />
      <Footer />
    </div>
  );
};

export default HomePage;
