import React, { useState, useRef } from 'react';
import Button from '../common/Button';

interface Option {
  id: number;
  optionText: string;
}

interface SurveyQuestion {
  id: number;
  questionText: string;
  options: Option[];
}

interface SurveyFormProps {
  questions: SurveyQuestion[];
  onOptionChange: (questionId: number, optionId: number) => void;
  onSubmit: () => void;
}

const SurveyForm: React.FC<SurveyFormProps> = ({ questions, onOptionChange, onSubmit }) => {
  const [selectedOptions, setSelectedOptions] = useState<{ [key: number]: number }>({});
  const [answers, setAnswers] = useState<{ [key: number]: number }>({});
  const questionRefs = useRef<(HTMLDivElement | null)[]>([]);

  const handleOptionChange = (questionId: number, optionId: number) => {
    setSelectedOptions(prev => ({ ...prev, [questionId]: optionId }));
    onOptionChange(questionId, optionId);
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [questionId]: optionId,
    }));

    const nextQuestionIndex = questions.findIndex(q => q.id === questionId) + 1;

    if (nextQuestionIndex < questions.length && questionRefs.current[nextQuestionIndex]) {
      const nextQuestion = questionRefs.current[nextQuestionIndex];

      if (nextQuestion) {
        if (window.innerWidth <= 768) {
          nextQuestion.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start'
          });
        } else {
          const offset = nextQuestion.getBoundingClientRect().top + window.scrollY - (window.innerHeight / 2) + (nextQuestion.offsetHeight / 2);
          window.scrollTo({ top: offset, behavior: 'smooth' });
        }
      }
    }
  };

  const handleSubmit = () => {
    const firstUnansweredQuestionIndex = questions.findIndex(
      question => selectedOptions[question.id] === undefined
    );

    if (firstUnansweredQuestionIndex !== -1 && questionRefs.current[firstUnansweredQuestionIndex]) {
      // 未回答の最初の質問にスクロール
      const firstUnansweredQuestion = questionRefs.current[firstUnansweredQuestionIndex];
      if (firstUnansweredQuestion) {
        firstUnansweredQuestion.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start'
        });
      }
    } else {
      // 全て回答されていれば、onSubmitを実行
      onSubmit();
    }
  };

  return (
    <div className="l_survey-form">
      <div className="survey-form">
        {questions.map((question, index) => (
          <div
            className="question"
            key={question.id}
            style={{ backgroundColor: selectedOptions[question.id] ? '#fff' : '#F6FBFF' }}
            ref={el => questionRefs.current[index] = el}
          >
            <p>Q{index + 1}.  {question.questionText}</p>
            <div className="options">
              {question.options.map(option => (
                <label key={option.id}>
                  <input
                    type="radio"
                    name={`question_${question.id}`}
                    onChange={() => handleOptionChange(question.id, option.id)}
                  />
                  <span className="custom-radio"></span>
                  <span>
                    {option.optionText
                      ? option.optionText.split(/(\s+|\\n)/).map((text, index) => (
                        <React.Fragment key={index}>
                          {text.trim() !== '' ? text : <br />}
                        </React.Fragment>
                      ))
                      : 'Option text not available'}
                  </span>
                </label>
              ))}
            </div>
          </div>
        ))}
      </div>
      <p className="survey_form_txt">質問は以上です。お疲れさまでした！</p>
      <Button
        onClick={handleSubmit}
        className="survey_form_button"
        style={{
          backgroundColor: '#1C2B74',
          color: '#ffffff',
          border: '1px solid #1C2B74',
        }}
      >
        分析結果を見る
      </Button>
    </div>
  );
};

export default SurveyForm;
