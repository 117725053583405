// /components/contact/ContactForm.tsx
import React, { useState } from 'react';
import axios from 'axios';
import Button from '../common/Button';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../../config';

const ContactForm: React.FC<{ userId?: string }> = ({ userId }) => {
  const [name, setName] = useState('');
  const [affiliation, setAffiliation] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [inquiryType, setInquiryType] = useState('');
  const [message, setMessage] = useState('');

  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const contactData = {
      name,
      affiliation,
      email,
      phone,
      address,
      inquiryType,
      message,
      userId,
    };

    try {
      await axios.post(`${API_BASE_URL}/api/survey/contact`, contactData);
      navigate('/thanks');
    } catch (error) {
      console.error('送信エラー:', error);
    }
  };

  return (
    <div className="contact-form">
      <p className="contact_page_desc">
        挑戦力診断「WATARE」に関する詳しい情報やご説明をご希望の方は、以下のフォームに必要事項をご記入の上、お問い合わせください。また、あらかじめ弊社プライバシーポリシーをご確認ください。
      </p>
      <form onSubmit={handleSubmit}>
        <div className="l_form-group">
          <div className="form-group">
            <label htmlFor="name">お名前<span className="required-indicator">　必須</span></label>
            <input
              id="name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="affiliation">ご所属（企業名・大学名など）</label>
            <input
              id="affiliation"
              type="text"
              value={affiliation}
              onChange={(e) => setAffiliation(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">メールアドレス<span className="required-indicator">　必須</span></label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="phone">お電話番号</label>
            <input
              id="phone"
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="address">ご住所</label>
            <input
              id="address"
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label>お問い合わせ種別<span className="required-indicator">　必須</span></label>
            <div className="radio-group">
              <label>
                <input
                  type="radio"
                  name="inquiryType"
                  value="詳しく話を聞きたい"
                  checked={inquiryType === '詳しく話を聞きたい'}
                  onChange={(e) => setInquiryType(e.target.value)}
                  required
                />
                詳しく話を聞きたい
              </label>
              <label>
                <input
                  type="radio"
                  name="inquiryType"
                  value="資料がほしい"
                  checked={inquiryType === '資料がほしい'}
                  onChange={(e) => setInquiryType(e.target.value)}
                  required
                />
                資料がほしい
              </label>
              <label>
                <input
                  type="radio"
                  name="inquiryType"
                  value="運営企業について聞きたい"
                  checked={inquiryType === '運営企業について聞きたい'}
                  onChange={(e) => setInquiryType(e.target.value)}
                  required
                />
                運営企業について聞きたい
              </label>
              <label>
                <input
                  type="radio"
                  name="inquiryType"
                  value="その他"
                  checked={inquiryType === 'その他'}
                  onChange={(e) => setInquiryType(e.target.value)}
                  required
                />
                その他
              </label>
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="message">お問い合わせ内容</label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </div>
        </div>


        <p className="contact_page_txt">
          入力内容の確認画面は出ません。送信の前に内容をご確認ください。
        </p>

        <Button onClick={() => { }} className="submit-button custom">
          上記の内容で送信する
        </Button>
      </form>
    </div>
  );
};

export default ContactForm;
