// /components/common/Footer.tsx
import React from 'react';
import starpierLogo from '../../assets/img/starpier_logo.png';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <img src={starpierLogo} alt="Star Pier Logo" />
      <p>(C) Copyright STAR PIER Inc. All Rights Reserved.</p>
    </footer>
  );
};

export default Footer;
