// /components/common/Button.tsx
import React from 'react';

interface ButtonProps {
  onClick: () => void;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
}

const Button: React.FC<ButtonProps> = ({ onClick, children, className, disabled, style }) => {
  return (
    <button
      onClick={onClick}
      className={`btn ${className}`}
      disabled={disabled}
      style={style}
    >
      <span className="btn-content">
        {children}
      </span>
      <svg
        className="btn-icon"
        xmlns="http://www.w3.org/2000/svg"
        width="23"
        height="31"
        viewBox="0 0 23 31"
        fill="none"
        style={{ stroke: style?.color }}
      >
        <path d="M1 1L21 15.5C14.3333 20.3333 1 30 1 30" strokeWidth="2" />
      </svg>
    </button>
  );
};

export default Button;
