import React, { useRef, useState, useEffect } from 'react';
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList
} from 'recharts';

// データ型の定義
interface ResultsChartProps {
  data: {
    score: Record<string, number>;
    average: Record<string, number>;
  };
}

const ResultsChart: React.FC<ResultsChartProps> = ({ data }) => {
  const chartData = Object.keys(data.score).map(key => ({
    type: key,
    score: data.score[key] !== undefined ? data.score[key].toFixed(1) : '0.0',
    average: data.average[key] !== undefined ? data.average[key].toFixed(1) : '0.0'
  }));

  const chartContainerRef = useRef<HTMLDivElement>(null);
  const [chartWidth, setChartWidth] = useState<number>(800);
  const [chartHeight, setChartHeight] = useState<number>(400);
  const [isDataReady, setIsDataReady] = useState<boolean>(false);

  // 遅延してデータをセットする
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsDataReady(true);  // データをロード
    }, 500);  // 500ms の遅延

    return () => clearTimeout(timeoutId);
  }, []);

  const renderLegend = () => (
    <ul style={{ listStyleType: 'none', padding: 0, margin: 0, display: 'flex', justifyContent: 'center' }}>
      <li style={{ color: '#000', display: 'flex', alignItems: 'center' }}>
        <div style={{ backgroundColor: '#FFF', width: '24px', height: '24px', borderRadius: '50%', marginRight: '5px', border: '1px solid #1C2B74' }}></div>
        平均値
      </li>
    </ul>
  );

  useEffect(() => {
    const handleResize = () => {
      if (chartContainerRef.current) {
        const currentWidth = chartContainerRef.current.clientWidth;
        setChartWidth(currentWidth);

        const isMobile = window.innerWidth <= 768;
        const newHeight = currentWidth * (isMobile ? 1.0 : 0.5);
        setChartHeight(newHeight);
      }
    };

    window.addEventListener('resize', handleResize);

    const timeoutId = setTimeout(() => {
      handleResize();
    }, 0);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('resize', handleResize);
    };
  }, [chartContainerRef]);

  // isDataReady が true になってからチャートを表示する
  return (
    <div className="chart-container" ref={chartContainerRef}>
      {isDataReady && (
        <ComposedChart
          className="composed-chart"
          width={chartWidth}
          height={chartHeight}
          data={chartData}
          margin={{ top: 20, right: 0, bottom: 20, left: 0 }}
        >
          <XAxis
            dataKey="type"
          />
          <Legend
            content={renderLegend}
            layout="vertical"
            verticalAlign="top"
            align="center"
            wrapperStyle={{
              top: 0,
              left: 0,
              lineHeight: '40px',
              color: '#000'
            }}
            formatter={(value) => {
              switch (value) {
                case 'score':
                  return 'スコア';
                case 'average':
                  return '平均点';
                default:
                  return (
                    <span>
                      ● {value}
                    </span>
                  );
              }
            }}
          />
          <Bar
            dataKey="score"
            fill="#1C2B74"
            stroke="#1C2B74"
            strokeWidth={1}
            barSize={30}
            isAnimationActive={true}
            animationDuration={1500}
            animationBegin={0}
          >
            <LabelList dataKey="score" position="top" />
          </Bar>
          <Bar
            dataKey="average"
            fill="#fff"
            stroke="#1C2B74"
            strokeWidth={1}
            barSize={30}
            isAnimationActive={true}
            animationDuration={1500}
            animationBegin={0}
          >
            <LabelList dataKey="average" position="top" />
          </Bar>
        </ComposedChart>
      )}
    </div>
  );
};

export default ResultsChart;