// /pages/ResultsPage.tsx
import React, { useEffect, useState } from 'react';
import ResultsChart from '../components/results/ResultsChart';
import { useNavigate } from 'react-router-dom';
import Header from '../components/common/Header';
import Button from '../components/common/Button';
import watareLogo from '../assets/img/watare_logo.png';
import starpierLogo from '../assets/img/starpier_logo.png';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import useScrollToTop from '../hooks/hook';
import { API_BASE_URL } from '../config';

const ResultsPage: React.FC = () => {
    const navigate = useNavigate();

    const location = useLocation();
    const { userId } = location.state || {};
    const [individualResults, setIndividualResults] = useState<number[]>([]);
    const [overallResults, setOverallResults] = useState<number[]>([]);
    const [deviationResults, setDeviationResults] = useState<number[]>([]);
    const [commentResults, setCommentResults] = useState<string[]>([]);

    useScrollToTop();

    const handlePdfClick = () => {
        const input = document.getElementById('chart-container');
        if (input) {
            html2canvas(input).then(canvas => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'mm', 'a4');

                // A4 サイズ
                const imgWidth = 210; // A4 幅
                const pageHeight = 295; // A4 高さ
                const margin = 10; // 余白のサイズ
                const imgHeight = canvas.height * imgWidth / canvas.width;
                let heightLeft = imgHeight;

                // 余白を考慮した位置
                let position = margin;

                // ページに合わせて画像のサイズを調整
                const height = Math.min(imgHeight, pageHeight - 2 * margin);

                pdf.addImage(imgData, 'PNG', margin, position, imgWidth - 2 * margin, height);
                heightLeft -= pageHeight - 2 * margin;

                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    pdf.addPage();
                    pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;
                }

                pdf.save('results.pdf');
            });
        }
    };

    const handleContactClick = () => {
        sessionStorage.setItem('userId', userId);
        navigate(`/contact`);
    };

    const handleCompanyClick = () => {
        window.open('https://starpier.jp/', '_blank'); // 外部リンクを新しいタブで開く
    };

    useEffect(() => {
        const fetchResults = async () => {
            try {
                // すべての結果をまとめて取得
                const response = await axios.post(`${API_BASE_URL}/api/survey/results`, { userId });
                console.log('Results:', response.data); // 取得した結果をコンソールに出力

                // 個別結果、全体結果、コメント結果をセット
                setIndividualResults(response.data.individualResults);
                setOverallResults(response.data.overallResults);
                setCommentResults(response.data.comments);

            } catch (error) {
                console.error('Error fetching results:', error);
            }
        };

        fetchResults();
    }, [userId]);

    const summary = {
        score: {
            '謙虚さ': individualResults[0],
            'アサーティブネス': individualResults[1],
            '創造性': individualResults[2],
        },
        average: {
            '謙虚さ': overallResults[0],
            'アサーティブネス': overallResults[1],
            '創造性': overallResults[2],
        }
    };

    return (
        <div className="l_container">
            <Header />
            <div className="results-page">
                <p className="results-subtitle">質問への回答、お疲れさまでした。以下の内容が、あなたの現在の挑戦力です。</p>
                <div id="chart-container">
                    <ResultsChart data={summary} />
                    <div className="l_results-description">
                        <p>◯一言コメント</p>
                        {commentResults.map((comment, index) => (
                            <span key={index}>{comment}</span> // 各コメントを個別に表示
                        ))}
                    </div>
                </div>
                <Button onClick={handlePdfClick} className="pdf-button">
                    診断結果をPDFファイルで<br className="sp"></br>ダウンロードする
                </Button>
                <div className="results_page_watare">
                    <img src={watareLogo} alt="WATARE Logo" className="logo" />
                    <p className="description">
                        ここにWATAREの正式版に関する文章が入ります。内容は別途検討する必要があります。おおよそ400文字程度が理想です。ここにWATAREの正式版に関する文章が入ります。内容は別途検討する必要があります。おおよそ400文字程度が理想です。ここにWATAREの正式版に関する文章が入ります。内容は別途検討する必要があります。おおよそ400文字程度が理想です。
                    </p>
                    <Button onClick={handleContactClick} className="contact-button custom">
                        WATAREに関する<br className="sp"></br>お問い合わせ・資料請求をする
                    </Button>
                </div>
                <div className="results_page_staepier">
                    <img src={starpierLogo} alt="STAR PIER Logo" className="logo" />
                    <p className="description">
                        STAR PIERの紹介文がここに入ります。200字程度、内容は別途検討する必要があります。STAR PIERの紹介文がここに入ります。200字程度、内容は別途検討する必要があります。
                    </p>
                    <Button onClick={handleCompanyClick} className="company-button custom">
                        STAR PIERの<br className="sp"></br>ウェブサイトを見る
                    </Button>
                </div>
                <p className="copyright">(C)Copyright STAR PIER Inc. All Rights Reserved.</p>
            </div>
        </div>
    );
};

export default ResultsPage;
