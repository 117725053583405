// /pages/ContactPage.tsx
import React, { useEffect, useState } from 'react';
import ContactForm from '../components/contact/ContactForm';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import Button from '../components/common/Button';
import useScrollToTop from '../hooks/hook';

const ThanksPage: React.FC = () => {
  useScrollToTop();

  return (
    <div className="l_container">
      <Header />
      お問い合わせありがとうございました。
      <Footer />
    </div>
  );
};

export default ThanksPage;