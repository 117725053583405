// /components/common/Header.tsx
import React from 'react';
import watareLogo from '../../assets/img/watare_logo.png';

const Header: React.FC = () => {
  return (
    <header className="header">
      <img src={watareLogo} alt="Watare Logo" />
    </header>
  );
};

export default Header;
