// /pages/ContactPage.tsx
import React, { useEffect, useState } from 'react';
import ContactForm from '../components/contact/ContactForm';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import useScrollToTop from '../hooks/hook';

const ContactPage: React.FC = () => {

  useScrollToTop();

  const userId = sessionStorage.getItem('userId') || undefined;

  return (
    <div className="l_container">
      <Header />
      <div className="contact-page">
        <ContactForm userId={userId} />
      </div>
      <Footer />
    </div>
  );
};

export default ContactPage;